import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo/Seo"
import { Product } from "../components/product/Product"

const IndexPage = ({ data: { products } }) => {
  const allProducts = products.nodes

  const [list, setList] = React.useState([...allProducts.slice(0, 6)])
  const [loadMore, setLoadMore] = React.useState(false)
  const [hasMore, setHasMore] = React.useState(allProducts.length > 6)
  const loadRef = React.useRef()

  // Handle intersection with load more div
  const handleObserver = entities => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }

  //Initialize the intersection observer API
  React.useEffect(() => {
    var options = {
      root: null,
      rootMargin: "50px",
      threshold: 1,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])

  // Handle loading more articles
  React.useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allProducts.length
      const nextResults = isMore
        ? allProducts.slice(currentLength, currentLength + 3)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line
  // console.log(allProducts);
  React.useEffect(() => {
    const isMore = list.length < allProducts.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <Layout>
      <Seo title="LOOKBOOKS" />
      <main className="overflow-x-hidden md:-mt-8">
        <div className="flex flex-wrap px-6 -mx-12 justify-evenly">
          {list.map(product => {
            return (
              product.cover && (
                <Product key={product.shopifyId} product={product} />
              )
            )
          })}
        </div>
        <div ref={loadRef}>{hasMore ? <div></div> : <p></p>}</div>
      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    products: allSanityShopifyProduct(sort: { order: ASC, fields: order }) {
      nodes {
        ...ProductCard
      }
    }
  }
`
