import React from "react"

// import UtilImage from "../util/UtilImage"
import "./book.scss"
import client from "../../utils/sanityClient"
import imageUrlBuilder from "@sanity/image-url"

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}
// import { useMediaQuery } from "react-responsive"
const TestBook = ({ book }) => {
  // const [load, setLoad] = React.useState(true)
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 })
  function getDepth() {
    let width = 1
    if (book.cover && book.cover.size) {
      if (book.cover.size.width === "5") {
        width = 1
      } else if (book.cover.size.width !== undefined) {
        width = 1 + (book.cover.size.width - 5) / 5
        if (width < 0) {
          return width
        } else {
          return width
        }
      }
    }
    return width
  }
  function getSize() {
    let size = 1
    if (book.cover && book.cover.size) {
      if (book.cover.size.size === "5") {
        size = 1
      } else if (book.cover.size.size !== undefined) {
        size = book.cover.size.size - 5
        if (size < 0) {
          return size
        } else {
          return size
        }
      }
    }
    return size
  }

  return (
    <figure
      className={`relative flex flex-col flex-wrap items-center justify-center w-full h-88 cube-container  `}
    >
      <div
        className={`relative flex flex-col justify-center max-h-full  cube ${getSize()}`}
      >
        <figure
          className="left"
          style={{
            backgroundImage: `url(
              ${urlFor(book.cover.spine.asset.id)
                .width(1200)
                .auto("format")
                .url()}
            )`,
            transform: `translateX(-${getDepth()}rem) rotateY(270deg) translateX(-${getDepth()}rem)`,
            width: `${getDepth() * 2}rem`,
          }}
        ></figure>

        <figure
          className="right"
          style={{
            transform: `translateX(${getDepth()}rem) rotateY(-90deg) translateX(-${getDepth()}rem)`,
            width: `${getDepth() * 2}rem`,
          }}
        ></figure>
        {
          book?.cover?.back?.asset?.id &&
        <figure
          className="back"
          style={{
            backgroundImage: `url(
              ${urlFor(book.cover.back.asset.id)
                .width(1200)
                .auto("format")
                .url()}
            )`,
            transform: `translateZ(-${getDepth() * 2}rem) rotateY(180deg)`,
          }}
        ></figure>
        }
        <img
          src={urlFor(book.cover.cover.asset.id)
            .width(1200)
            .auto("format")
            .url()}
          className="front "
          style={{
            transform: `rotateY(0deg)`,
          }}
          alt={book.title}
        />
      </div>
    </figure>
  )
}

export default TestBook
