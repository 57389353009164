import React from "react"
import { graphql } from "gatsby"
import { Waypoint } from "react-waypoint"
import { Base64 } from "js-base64"
import { formatPrice } from "../../utils/format-price"
import RichText from "../text/RichText"
// import Book from "../book/Book"
import TestBook from "../util/TestBook"
import { AddToCart } from "../add-to-cart/AddToCart"
import { StoreContext } from "../../context/store-context"
export function Product({ product }) {
  const ref = React.useRef()
  const goingIn = () => {
    ref.current.classList.add("in-view")
  }
  const goingOut = () => {
    ref.current.classList.remove("in-view")
  }
  function getPadding(cover) {
    if (cover) {
      let size = cover?.size?.size

      if (size === "medium") {
        return "px-4"
      } else if (size === "small") {
        return "px-7"
      } else if (size === "xsmall") {
        return "px-10"
      }
    }
  }
  const { client, checkout } = React.useContext(StoreContext)

  const afs = product.sourceData.availableForSale
  const [available, setAvailable] = React.useState(afs)

  let variantId = product.variants[0].shopifyVariantID
  const inCart = checkout.lineItems.some(
    product => variantId === product.variant.id
  )
  const variantPrice = formatPrice("GBP", product.maxVariantPrice)

  const [price, setPrice] = React.useState(variantPrice)
  const [title, setTitle] = React.useState(product.title)
  // console.log(product.title)

  // const WAIT_TIME = 1000
  // const checkAvailablity = React.useCallback(
  //   productId => {
  //     client.product.fetch(productId).then(fetchedProduct => {
  //       const result =
  //         fetchedProduct?.variants.filter(
  //           variant => variant.id === variantId
  //         ) ?? []

  //       if (result.length > 0) {
  //         setClientProduct(fetchedProduct)
  //         setAvailable(result[0].available)
  //       }
  //     })
  //   },
  //   [variantId, client.product]
  // )
  // React.useEffect(() => {
  //   checkAvailablity(product.shopifyId)
  //   const updateAvailablity = setInterval(() => {
  //     checkAvailablity(product.shopifyId)
  //   }, WAIT_TIME)
  //   return () => clearInterval(updateAvailablity)
  // }, [checkAvailablity, product.shopifyId])

  React.useEffect(() => {
    client.product.fetch(product.shopifyId).then(fetchedProduct => {
      if(fetchedProduct){
        setTitle(fetchedProduct.title)

        const result =
          fetchedProduct?.variants.filter(
            variant => variant.id === Base64.decode(variantId)
          ) ?? []
        if (result.length > 0) {
          setAvailable(result[0].available)
          const price = formatPrice("GBP", result[0].price.amount)
          setPrice(price)
        }
      }
    })
  }, [product.shopifyId, client.product, variantId])

  // const available = product.sourceData.availableForSale

  // const [clickOnImage, setClickOnImage] = React.useState(false)

  // const handleClickOnImage = () => {
  //   setClickOnImage(true)
  // }

  return (
    <Waypoint
      onEnter={() => {
        goingIn()
      }}
      onLeave={() => {
        goingOut()
      }}
      topOffset="-30%"
    >
      <article
        ref={ref}
        className="relative flex flex-col w-full p-12 py-6 text-base xs:text-sm md:w-1/2 lg:w-1/3"
      >
        {/* <Link className="absolute inset-0" to={product.slug}></Link> */}

        {/* <div className={getPadding(product.cover)}>
          <Book book={product} />
        </div> */}
        <div className={getPadding(product.cover)}>
          <TestBook book={product} />
        </div>

        <footer className="flex flex-col flex-wrap flex-1 pt-4 leading-tighter">
          <div className="flex w-full">
            <div className="flex-1">
              {/* <Link to={product.slug}> */}
              <h2 className="pr-4">{title}</h2>
              {/* </Link> */}
              <div className="w-full italic normal-case description">
                {product.shortDescription &&
                  product.shortDescription.map(child => {
                    child.markDefs = []
                    return <RichText key={child._key} blocks={child} />
                  })}
              </div>
            </div>
            <div className="flex flex-col items-end text-right transition duration-300 delay-100 ">
              {available ? (
                <nav className="flex flex-col items-end ml-auto text-right normal-case ">
                  <span>{price}</span>

                  {!inCart ? (
                    <AddToCart
                      variantId={variantId}
                      quantity={1}
                      available={available}
                      className="pb-0 leading-none uppercase border-b-2 border-transparent hover:border-red"
                    />
                  ) : (
                    <span className="uppercase border-b-2 border-transparent whitespace-nowrap">
                      ✓ In cart
                    </span>
                  )}
                </nav>
              ) : (
                <nav className="flex flex-col items-end ml-auto text-right uppercase">
                  <span>Sold out</span>
                  <a
                    href="mailto:info@lookbooks.store"
                    className="pb-0 leading-none uppercase border-b-2 border-transparent hover:border-red"
                  >
                    Enquire
                  </a>
                </nav>
              )}
            </div>
          </div>
        </footer>
      </article>
    </Waypoint>
  )
}

export const query = graphql`
  fragment ProductCard on SanityShopifyProduct {
    title
    slug: gatsbyPath(filePath: "/products/{SanityShopifyProduct.handle}")
    handle
    shopifyId
    description {
      _key
      _type
      list
      style
      children {
        _key
        _type
        marks
        text
      }
    }
    images {
      _key
      asset {
        gatsbyImageData(
          formats: AUTO
          layout: FULL_WIDTH
          placeholder: NONE
          backgroundColor: "#FFD401"
        )
      }
    }
    variants {
      shopifyVariantID
    }
    shortDescription {
      _key
      _type
      list
      style
      children {
        _key
        _type
        marks
        text
      }
    }
    sourceData {
      availableForSale
    }
    maxVariantPrice
    cover {
      cover {
        asset {
          id
          gatsbyImageData(
            formats: AUTO
            placeholder: NONE
            backgroundColor: "#FFD401"
          )
          small: gatsbyImageData(
            formats: AUTO
            placeholder: NONE
            backgroundColor: "#FFD401"
            width: 275
          )
        }
      }
      _key
      back {
        asset {
          id
          gatsbyImageData(
            formats: AUTO
            placeholder: NONE
            backgroundColor: "#FFD401"
          )
          small: gatsbyImageData(
            formats: AUTO
            placeholder: NONE
            backgroundColor: "#FFD401"
            width: 275
          )
        }
      }
      size {
        size
        width
      }
      spine {
        asset {
          id
          gatsbyImageData(
            formats: AUTO
            backgroundColor: "#FFD401"
            placeholder: NONE
          )
        }
      }
    }
  }
`
